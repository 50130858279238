
$(window).on("resize turbolinks:load", function () {
  $("#mySidebar").css("width", "0");
  $(".main").css("right", "0");
  $(".header").css("right", "0");
  $(".bars-close").addClass("d-none");
  $(".bars-mobile").removeClass("d-none").addClass("d-block");
  $("body").css("overflow", "auto");
  $(".sign_in").removeClass("d-none").addClass("d-flex");
  $(".layout_blur").css("display", "none");
});
